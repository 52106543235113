<template>
  <div>
    <div class="panel-box1">
      <slot name="box1"></slot>
    </div>
    <div class="position-relative" style="padding:2px 26px;background-color:white">
      <div class="divide-dashed"></div>
      <div class="left-rotate-rectangle"></div>
      <div class="right-rotate-rectangle"></div>
    </div>
    <div class="panel-box2">
      <slot name="box2"></slot>
    </div>
  </div>
</template>
<script>
  export default {
    name: "PanelTag",
    created() {
    },
    data() {
      return {}
    },
    methods: {}
  }
</script>
<style lang="stylus" scoped>
  @import '~assets/css/lp_main'
  .divide-dashed
    border-top 2px dashed $border

  .left-rotate-rectangle
  .right-rotate-rectangle
    width 15px
    height 15px
    background-color $background
    transform rotate(45deg)
    position absolute

  .left-rotate-rectangle
    box-shadow inset -3px 3px 4px 0 rgba(0,0,0, 0.1)
    left -8px
    top -4px

  .right-rotate-rectangle
    box-shadow inset 3px -3px 4px 0 rgba(0,0,0, 0.1)
    right -8px
    top -4px

  .panel-box1
  .panel-box2
    background-color white
    padding 30px 26px

  .panel-box1
    box-shadow 0px 0px 8px 5px rgba(0,0,0, 0.1)
  .panel-box2
    box-shadow 0px 3px 8px 3px rgba(0,0,0, 0.1)

  .management-card
    background-color white
    padding 24px
    box-shadow 0 0px 5px 4px rgba(0,0,0, 0.1)
    width 587px
    height 198px
    margin-bottom 28px
    border-radius $radius

  .origin-price-text
    color $text2
    text-decoration line-through
    text-decoration-color $primary
</style>